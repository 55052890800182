.root {
}

.root_error {
}

.container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 32px;
}

.audioPlayer {
	flex-grow: 1;
}
