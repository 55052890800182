.root {
}

.required {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	color: var(--vkui--color_text_negative);
	line-height: var(--vkui--font_subhead--line_height--compact);
}
