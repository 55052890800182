.root {
	display: flex;
	align-items: center;
	gap: 16px;
}

.root_disabled .progressContainer {
	pointer-events: none;
}

.progressContainer {
	flex-grow: 1;
	display: flex;
	align-items: center;
	height: 20px;
	cursor: pointer;
}

.progress {
	transition: height 0.2s ease-in-out;
	flex-grow: 1;
}

.progressContainer:hover .progress {
	height: 6px;
}

.control {
}

.time {
	color: var(--vkui--color_text_secondary);
}

.volumeBlock {
	width: 40px;
	display: flex;
	justify-content: center;
}

.volumeContainer {
	display: flex;
	flex-direction: column;
	justify-content: end;
	height: 50px;
	width: 6px;
	cursor: pointer;
	transition: width 0.2s ease-in-out;
	border-radius: 12px;
	background-color: var(--vkui--color_track_background);
}

.volumeContainer:hover {
	width: 8px;
}

.volume {
	border-radius: 12px;
	background-color: var(--vkui--color_stroke_accent);
	transition: height 0.2s ease;
}
