.username {
	display: inline-flex;
	align-items: center;
	color: var(--paprika-podcast--primary_color);
	cursor: pointer;
	transition: filter 0.2s ease-in-out;
}

.username:hover {
	filter: brightness(110%);
}

.popover {
	z-index: 10000 !important;
	overflow: hidden;
	background-color: var(--vkui--color_background_tertiary) !important;
}

.chevronIcon {
	display: inline-block;
	transition: transform 0.1s ease-in-out;
}

.chevronIcon__open {
	transform: rotate(180deg) translate(0px, -4px);
}
