.root {
	max-width: var(--paprika-podcast--max_app_width);
	flex-grow: 1;
	box-shadow: none !important;
	margin: 36px 0;
	display: flex;
	flex-direction: column;
}

.root_content {
	align-self: center;
	flex-grow: 0;
}

.root_fullscreen {
	margin: 0;
	max-width: none;
	border-radius: 0 !important;
}

.container {
	flex-grow: 1;
}

@media screen and (max-width: 768px) {
	.root {
		border-radius: 0 !important;
		max-width: 100%;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	padding: 12px 16px;
}

.action {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
