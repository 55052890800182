.root {
	height: var(--vkui--size_panel_header_height--regular);
	padding: var(--vkui_internal--safe_area_inset_top) 16px 0 16px;
	background: var(--vkui--color_background_content);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	position: fixed;
	width: 100%;
	z-index: 1000;
}

.inner {
	flex-grow: 1;
	display: flex;
	align-items: center;
	max-width: var(--paprika-podcast--max_app_width);
}

.inner__before {
	margin-right: 16px;
}

@media screen and (max-width: 560px) {
	.inner {
		justify-content: space-between;
	}
	.inner__content {
		display: none;
	}
}

.inner__content {
	flex-grow: 1;
	color: var(--vkui--color_text_primary);
	font-weight: 500;
	font-size: 24px;
	padding-bottom: 4px;
}

.inner__after {
	display: flex;
	gap: 16px;
	align-items: center;
}
