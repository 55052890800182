.root {
	width: 450px;
}

.successIcon {
	color: var(--vkui--color_icon_positive);
}

.errorIcon {
	color: var(--vkui--color_icon_negative);
}

.fetchingSpinner {
	padding: 6px;
}

.fetchingSpinner > svg {
	color: var(--vkui--color_icon_accent);
}
