:root {
	--paprika-podcast--max_app_width: 768px;
	--paprika-podcast--primary_color: #e0005d;
}

body {
	font-family: var(--vkui--font_family_accent);
}

* {
	box-sizing: border-box;
}

svg {
	box-sizing: content-box;
}
