.root {
}

.root_error {
}

.container {
	display: flex;
	align-items: center;
	gap: 32px;
}

.root_error .image :global(.vkuiImageBase__border) {
	border-color: var(--vkui--color_stroke_negative);
}

.root_error .image {
	background-color: var(--vkui--color_background_negative_tint);
}
