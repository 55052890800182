.root {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.content {
	padding: 52px 0 0 0;
	display: flex;
	justify-content: center;
	flex-grow: 1;
}
