.root {
}

.root.root_removed {
	opacity: 0.55;
}

.num {
	border-radius: 100%;
	min-width: 24px;
	height: 24px;
	background-color: var(--vkui--color_background_secondary);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	color: var(--vkui--color_text_primary);
}
