.root {
}

.root.root_removed {
	opacity: 0.55;
}

.episodesCount {
	display: flex;
	flex-direction: column;
	align-items: center;
}
