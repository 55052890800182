.root {
	background-image: url('../../assets/images/microphone.svg') !important;
	background-repeat: no-repeat;
	background-position: 50% 50% !important;
	background-size: 200% 200% !important;
}

.content {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px;
}

.root .content .title {
	font-size: 100px;
	line-height: 130px;
	padding: 20px;
}

.site {
	color: var(--paprika-podcast--primary_color);
	background-color: var(--vkui--color_text_primary);
	padding: 8px 20px 20px 20px;
	font-size: 100px;
	line-height: 100px;
	font-weight: 700;
	max-width: 100%;
	overflow-wrap: break-word;
}

@media screen and (max-width: 1180px) {
	.root .content .title {
		font-size: 80px;
		line-height: 100px;
	}

	.site {
		font-size: 80px;
		line-height: 80px;
	}
}

@media screen and (max-width: 960px) {
	.root .content .title {
		font-size: 60px;
		line-height: 80px;
	}

	.site {
		font-size: 60px;
		line-height: 60px;
	}
}

@media screen and (max-width: 370px) {
	.root .content .title {
		font-size: 40px;
		line-height: 60px;
	}

	.site {
		font-size: 40px;
		line-height: 40px;
	}
}

.root .content .button {
	height: 60px;

	align-items: center;
	display: flex;
}

.root .content .button__text {
	font-size: 30px;
	line-height: 30px;
}

@media screen and (max-width: 460px) {
	.root .content .button__text {
		font-size: 20px;
		line-height: 20px;
	}
}
