.root {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	background-color: var(--vkui--color_background_content);
	display: flex;
	justify-content: center;
	align-items: center;
}

.root .loading {
	color: var(--vkui--color_icon_accent);
}
