.root {
}

.content {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.imageContainer .image {
	height: 270px;
	border-radius: 10px;
	align-items: center;
	background-color: var(--vkui--color_background_secondary);
	background-size: cover;
	color: var(--vkui--color_icon_secondary);
	display: flex;
	flex-shrink: 0;
	isolation: isolate;
	justify-content: center;
	position: relative;
	object-fit: cover;
}

@media screen and (max-width: 768px) {
	.imageContainer {
		flex-grow: 1;
	}

	.image {
		width: 100%;
	}

	.formContainer {
		flex-grow: 1;
	}
}
