.root {
	height: var(--vkui--size_panel_header_height--regular);
	padding: var(--vkui_internal--safe_area_inset_top) 16px 0 16px;
	background: var(--vkui--color_background_content);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	font-size: 13px;
}
